import React, { useEffect, useReducer, Fragment } from 'react';
import Select from 'react-select';
import { Chart } from 'react-google-charts';
import Loader from '../common/loader';
import api from '../../config/axios';
import { dateQuery } from '../../utils/date';
import Breadcrumb from '../common/breadcrumb';
import utils from '../../utils/miscUtils';
import { useStateValue } from '../../store';
import Datatable from '../common/datatable';

const { currency } = utils;

const LineOptionsRefills = {
    chartArea: { left: '10%', top: '10%', width: '85%', height: '80%' },
    colors: ['#00a4d3'],
    legend: 'none',
};

const LineOptionsRevenue = {
    chartArea: { left: '10%', top: '10%', width: '85%', height: '80%' },
    colors: ['green'],
    legend: 'none',
};

const arrayFilterDays = [
    {
        label: 'Este mes',
        value: 'currently-month',
    },
    {
        label: 'Mes pasado',
        value: 'last-month',
    },
    {
        label: 'Todos los tiempos',
        value: 'all',
    },
];

const getDatesRange = (type) => {
    let dates = { startDate: null, endDate: null };
    const startDate = new Date();
    const endDate = new Date();

    switch (type) {
        case 'currently-month':
            dates.startDate = dateQuery(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
            dates.endDate = dateQuery(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0));
            break;
        case 'last-month':
            dates.startDate = dateQuery(
                new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1),
            );
            dates.endDate = dateQuery(new Date(endDate.getFullYear(), endDate.getMonth(), 0));
            break;
        case 'all':
            dates.startDate = dateQuery(new Date(2020, 0, 1));
            dates.endDate = dateQuery(endDate);
            break;
    }
    return dates;
};

function Dashboard(props) {
    const franchiseId = props.match.params.id;

    const [{ auth }] = useStateValue();
    const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {
        resolved: false,
        loading: true,
        data: {
            franchises: null,
            purchasesGrouped: null,
            salesV2: null,
        },
        error: null,
    });
    const [filters, setFilters] = useReducer((s, a) => ({ ...s, ...a }), {
        currency: 'COP',
        franchise: auth.user.franchiseId || '5e95f51f-5b66-4c58-b2af-7a54e8b1df1e',
        range: 'currently-month',
    });

    const date = new Date();
    // https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth
    // getMonth() returns 0-11
    const currentMonth = date.getMonth() + 1;
    const firstYearDay = new Date(date.getFullYear(), 0, 1);
    const lastYearDay = new Date(date.getFullYear(), 11, 31);

    useEffect(() => {
        const { startDate, endDate } = getDatesRange(filters.range);

        if (true) {
            setState({
                resolved: false,
                loading: true,
                data: {
                    ...state.data,
                    purchasesGrouped: null,
                    salesV2: null,
                },
                error: null,
            });

            Promise.all([
                fetchSalesGrouped(firstYearDay, lastYearDay, franchiseId, filters.currency),
                fetchSalesV2(startDate, endDate, franchiseId, filters.currency),
            ])
                .then((values) => {
                    const data = {
                        ...state.data,
                        purchasesGrouped: values[0],
                        salesV2: values[1],
                    };
                    setState({
                        ...state,
                        loading: false,
                        resolved: true,
                        data,
                    });
                })
                .catch((error) => console.log(error));
        }
    }, [filters.currency, filters.range]);

    /**
     * Fetch total amount by currency
     * @param {String} startDate YYYY-MM-DD
     * @param {String} endDate YYYY-MM-DD
     * @returns Object
     */
    const fetchSalesV2 = async (startDate, endDate, franchiseId, currency) => {
        const { data } = await api.get(
            `/purchase/range?startDate=${startDate}&endDate=${endDate}&franchiseId=${franchiseId}&currency=${currency}`,
        );
        const totalSales = data.data;

        return totalSales;
    };

    /**
     * Fetch total amount by currency
     * @param {String} startDate YYYY-MM-DD
     * @param {String} endDate YYYY-MM-DD
     * @returns Object
     */
    const fetchSalesGrouped = async (startDate, endDate, franchiseId, currency) => {
        const { data } = await api.get(
            `/purchase/sales-grouped?startDate=${startDate}&endDate=${endDate}&franchiseId=${franchiseId}&currency=${currency}`,
        );
        const totalSales = data.data;

        return totalSales;
    };

    if (state.loading)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const rangeSelected = arrayFilterDays.find((item) => item.value === filters.range);

    return (
        <Fragment>
            <Breadcrumb title="Dashboard" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-4 col-md-6 col-12 mb-2">
                        <Select
                            name="franchise"
                            defaultValue={{
                                value: rangeSelected.value,
                                label: rangeSelected.label,
                            }}
                            placeholder="Seleccione franquicia"
                            onChange={(value) =>
                                setFilters({
                                    ...filters,
                                    range: value.value,
                                })
                            }
                            options={arrayFilterDays}
                            isSearchable={false}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-12">
                        <Select
                            name="franchise"
                            defaultValue={{
                                value: filters.currency,
                                label: filters.currency,
                            }}
                            placeholder="Seleccione moneda"
                            onChange={(value) =>
                                setFilters({
                                    ...state,
                                    currency: value.value,
                                })
                            }
                            options={[
                                {
                                    label: 'COP',
                                    value: 'COP',
                                },
                                {
                                    label: 'USD',
                                    value: 'USD',
                                },
                                {
                                    label: 'VES',
                                    value: 'VES',
                                },
                            ]}
                            isSearchable={false}
                        />
                    </div>
                </div>
                <DashboardDetail
                    state={state}
                    filters={filters}
                    currentMonth={currentMonth}
                    auth={auth}
                />
            </div>
        </Fragment>
    );
}

function DashboardDetail(props) {
    const { state } = props;
    const { purchasesGrouped, salesV2 } = state.data;

    const getMonthLabel = (month, year) => {
        const labelMonth = Number(month) < 10 ? `0${month}` : month;

        return `${labelMonth}/${String(year).slice(2, 4)}`;
    };

    const parsePurchases = [
        [{ label: 'Mes' }, { type: 'number', label: 'Ventas' }],
        ...purchasesGrouped.map((item) => [
            getMonthLabel(item.monthNumber, item.year),
            Number(item.totalAmount),
        ]),
    ];

    const parseRefills = [
        [{ label: 'Mes' }, { type: 'number', label: 'Recargas' }],
        ...purchasesGrouped.map((item) => [
            getMonthLabel(item.monthNumber, item.year),
            Number(item.quantityRefills),
        ]),
    ];

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            return {
                producto: item.name,
                cantidad: item.quantitySold,
                ventas: `${currency(item.amountSold)}`,
                costo: `${currency(item.amountCost)}`,
                utilidad: `${currency(Number(item.amountSold) - Number(item.amountCost))}`,
            };
        });
        return parsed;
    };

    const salesParsed = parseToDatatable(salesV2);
    const totalVentas = salesV2
        .map((item) => item.amountSold)
        .reduce((a, b) => Number(a) + Number(b), 0);
    const totalCostos = salesV2
        .map((item) => item.amountCost)
        .reduce((a, b) => Number(a) + Number(b), 0);
    const totalUtilidad = totalVentas - totalCostos;

    return (
        <div>
            <div className="row mt-4">
                <div className="col-12 mt-2">
                    <div className="card">
                        <div className="card-body">
                            <Datatable
                                class="-striped -highlight"
                                multiSelectOption={false}
                                data={salesParsed}
                                defaultPageSize={salesV2.length}
                                showPageSizeOptions={false}
                                showPagination={false}
                                options={false}
                                manual
                            />
                            <div className="row mt-5">
                                <div className="col-xl-6 offset-xl-3 col-md-6 offset-md-3 col-12">
                                    <ul>
                                        <li className="item-table-dashboard">
                                            <span>Ventas</span>
                                            <span>{currency(totalVentas)}</span>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li className="item-table-dashboard">
                                            <span>Utilidad</span>
                                            <span>{currency(totalUtilidad)}</span>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li className="item-table-dashboard">
                                            <span>Comisión Aguaman (30%)</span>
                                            <span>{currency(totalUtilidad * 0.3)}</span>
                                        </li>
                                    </ul>
                                    <hr />
                                    <ul>
                                        <li className="item-table-dashboard">
                                            <span>Comisión Franquicia (70%)</span>
                                            <span
                                                className="text-success"
                                                style={{ fontSize: '1.1em' }}
                                            >
                                                <strong>{currency(totalUtilidad * 0.7)}</strong>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3>Gráficas de crecimiento</h3>
            <div className="row">
                <div className="col-xl-6 col-md-6 xl-50">
                    <div className="card order-graph sales-carousel">
                        <div className="card-header">
                            <h4>Ingresos (COP) x Mes</h4>
                            <div className="row">
                                <div className="col-12">
                                    <div className="small-chartjs">
                                        <div
                                            className="flot-chart-placeholder"
                                            id="simple-line-chart-sparkline-2"
                                        >
                                            <Chart
                                                height={'300px'}
                                                width={'100%'}
                                                chartType="LineChart"
                                                loader={<div>Cargando Datos</div>}
                                                data={parsePurchases}
                                                options={LineOptionsRevenue}
                                                legend_toggle
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 xl-50">
                    <div className="card order-graph sales-carousel">
                        <div className="card-header">
                            <h4>Recargas, botellones y kits x Mes</h4>
                            <div className="row">
                                <div className="col-12">
                                    <div className="small-chartjs">
                                        <div
                                            className="flot-chart-placeholder"
                                            id="simple-line-chart-sparkline-2"
                                        >
                                            <Chart
                                                height={'300px'}
                                                width={'100%'}
                                                chartType="LineChart"
                                                loader={<div>Cargando Datos</div>}
                                                data={parseRefills}
                                                options={LineOptionsRefills}
                                                legend_toggle
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
