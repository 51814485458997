import {
    Home,
    DollarSign,
    Droplet,
    Percent,
    List,
    Truck,
    Users,
    Bookmark,
    Bell,
} from 'react-feather';

export const MENUITEMSADMIN = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        icon: Home,
        type: 'link',
        badgeType: 'primary',
        active: false,
    },
    {
        title: 'Recargas',
        icon: Droplet,
        type: 'link',
        active: false,
        path: '/recargas',
    },
    {
        title: 'Clientes',
        icon: Users,
        path: '/clientes',
        type: 'link',
        active: false,
    },
    {
        title: 'Novedades',
        icon: Bell,
        path: '/novedades',
        type: 'link',
        active: false,
    },
    {
        title: 'Formas de pago',
        path: '/formas_de_pago',
        type: 'link',
        icon: DollarSign,
        active: false,
    },
    {
        title: 'Formas de entrega',
        path: '/formas_de_entrega',
        type: 'link',
        icon: Truck,
        active: false,
    },
    {
        title: 'Usuarios',
        path: '/usuarios',
        type: 'link',
        icon: Users,
        active: false,
    },
    {
        title: 'Franquicias',
        icon: Bookmark,
        path: '/franquicias',
        type: 'link',
        active: false,
    },
    {
        title: 'Cupones',
        icon: Percent,
        path: '/cupones',
        type: 'link',
        active: false,
    },
];

export const MENUITEMSFRANCHISE = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        icon: Home,
        type: 'link',
        badgeType: 'primary',
        active: false,
    },
    {
        title: 'Recargas',
        icon: Droplet,
        type: 'link',
        active: false,
        path: '/recargas',
    },
    {
        title: 'Clientes',
        icon: Users,
        path: '/clientes',
        type: 'link',
        active: false,
    },
];

export const MENUITEMSDELIVERY = [
    {
        title: 'Recargas',
        icon: Droplet,
        type: 'link',
        active: false,
        path: '/recargas',
    },
    {
        title: 'Nuevo cliente',
        icon: Users,
        type: 'link',
        active: false,
        path: '/nuevo-cliente',
    },
];
