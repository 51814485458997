import './index.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';
import { StateProvider, useStateValue } from './store';
import reducer, { mainState } from './store/reducers';
import { ToastContainer } from 'react-toastify';

// Components
import Dashboard from './components/dashboard';
import DashboardByFranchise from './components/dashboard/_id';
import DashboardByUser from './components/dashboard/users/_id';

//Sales
import Orders from './components/sales/orders';
import OrderDetail from './components/sales/order-detail';
import NewOrder from './components/sales/new-order';

// Subscriptions
import Subscriptions from './components/subscriptions';
import SubscriptionDetail from './components/subscriptions/subscription-detail';

// Clients
import Clientes from './components/clients';
import ClientDetail from './components/clients/detail';
import NewClient from './components/clients/new-client';
import ClientNotification from './components/clients/send-notification';

// Payment Methods
import PaymentMethods from './components/paymentMethods';

// Deliveries
import Deliveries from './components/deliveries';

// Franchises
import Franchises from './components/franchises';
import NewFranchise from './components/franchises/new';

// Users
import Users from './components/users';
//import NewFranchise from './components/franchises/new';

// Cupones
import Coupons from './components/coupons';
import CreateCoupon from './components/coupons/create';

// Novedades
import News from './components/news';

//Pages
import GeneralSettings from './components/settings/general';
import Login from './components/auth/login';
import Privacy from './components/settings/privacy';
import { getItem, keyLocalStorage } from './config/localStorage';
import FranchiseDetail from './components/franchises/_id';
import Profile from './components/settings/profile';

function Root() {
    const [logged, setLogged] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [mainStore, setMainState] = useState(false);

    useEffect(() => {
        const getUserData = () => {
            const user = getItem(keyLocalStorage);
            return user ? JSON.parse(user) : null;
        };
        const userData = getUserData();

        if (userData) {
            setMainState({ ...mainState, auth: { user: userData } });
            setLogged(true);
            setLoaded(true);
        } else {
            setMainState(mainState);
            setLoaded(true);
        }
    }, []);

    if (!loaded) return null;
    return (
        <StateProvider reducer={reducer} initialState={mainStore}>
            <ToastContainer />
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`}>
                            {logged ? (
                                <Redirect to={`${process.env.PUBLIC_URL}/recargas`} />
                            ) : (
                                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                            )}
                        </Route>
                        <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/privacy`}
                            component={Privacy}
                        ></Route>

                        <App>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/recargas`}
                                component={Orders}
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/recargas/nueva-recarga`}
                                component={NewOrder}
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/recargas/detalle/:id`}
                                component={OrderDetail}
                            />

                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/subscripciones`}
                                component={Subscriptions}
                            />
                            <Route
                                path={`${process.env.PUBLIC_URL}/subscripciones/:id`}
                                component={SubscriptionDetail}
                            />

                            <Route
                                path={`${process.env.PUBLIC_URL}/formas_de_pago`}
                                component={PaymentMethods}
                            />

                            <Route
                                path={`${process.env.PUBLIC_URL}/formas_de_entrega`}
                                component={Deliveries}
                            />

                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/clientes`}
                                component={Clientes}
                            />

                            <Route
                                path={`${process.env.PUBLIC_URL}/clientes/:id`}
                                component={ClientDetail}
                            />

                            <Route
                                path={`${process.env.PUBLIC_URL}/nuevo-cliente`}
                                exact
                                component={NewClient}
                            />

                            <Route
                                path={`${process.env.PUBLIC_URL}/notificacion`}
                                exact
                                component={ClientNotification}
                            />

                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/dashboard`}
                                component={Dashboard}
                                logged={logged}
                            />

                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/dashboard/:id`}
                                component={DashboardByFranchise}
                                logged={logged}
                            />

                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/dashboard/:id/users/:userId`}
                                component={DashboardByUser}
                                logged={logged}
                            />

                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/profile`}
                                component={Profile}
                                logged={logged}
                            />

                            <AdminRoute
                                exact
                                path={`${process.env.PUBLIC_URL}/franquicias`}
                                component={Franchises}
                                logged={logged}
                            />

                            <AdminRoute
                                exact
                                path={`${process.env.PUBLIC_URL}/franquicias/nueva-franquicia`}
                                component={NewFranchise}
                                logged={logged}
                            />

                            <AdminRoute
                                path={`${process.env.PUBLIC_URL}/franquicias/:id`}
                                component={FranchiseDetail}
                                logged={logged}
                            />

                            <AdminRoute
                                path={`${process.env.PUBLIC_URL}/settings/general`}
                                component={GeneralSettings}
                                logged={logged}
                            />

                            <AdminRoute
                                exact
                                path={`${process.env.PUBLIC_URL}/usuarios`}
                                component={Users}
                                logged={logged}
                            />

                            <AdminRoute
                                exact
                                path={`${process.env.PUBLIC_URL}/cupones`}
                                component={Coupons}
                                logged={logged}
                            />

                            <AdminRoute
                                exact
                                path={`${process.env.PUBLIC_URL}/novedades`}
                                component={News}
                                logged={logged}
                            />

                            <AdminRoute
                                path={`${process.env.PUBLIC_URL}/cupones/crear-coupon`}
                                component={CreateCoupon}
                                logged={logged}
                            />

                            {/* <Route path="*">
                                <NoMatch />
                            </Route> */}
                        </App>
                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        </StateProvider>
    );
}

const AdminRoute = ({ component: Component, restricted, logged, ...rest }) => {
    const [{ auth }] = useStateValue();
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route
            {...rest}
            render={(props) =>
                !logged || (auth.user.role && auth.user.role !== 'ADMIN') ? (
                    <Redirect to="/" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

function NoMatch() {
    return (
        <div>
            <h3>404 page</h3>
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));
