import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import useForm from 'react-hook-form';
import Loader from '../common/loader';
import api from '../../config/axios';
import utils from '../../utils/miscUtils';
import { date, time } from '../../utils/date';
import { useStateValue } from '../../store';
import { getBottlesPurchase, getTimeFormat, emojiRequested } from './utils';
import IconoBotellon from '../../assets/images/dashboard/icon-botellon.png';
import { Clock, DollarSign } from 'react-feather';

const { currency } = utils;

const ModalObservation = (props) => {
    const { visible, onSave, handleClose, observationClosestPurchase, transactionId } = props;
    const { handleSubmit, register } = useForm();

    const handleSave = (data) => {
        const observation = data.observation;

        // transactionId is undefined when order is delivered
        // is ! null when transaction need to be updated
        onSave(observation, transactionId);
    };

    return (
        <Modal open={visible} onClose={handleClose} classNames={{ modal: 'customModal' }}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Observación en el pedido
                </h5>
            </div>
            <div className="modal-body">
                {observationClosestPurchase && (
                    <div className="form-group">
                        <p style={{ fontWeight: 'bold' }}>
                            Nota anterior:{' '}
                            <span style={{ color: 'red' }}>{observationClosestPurchase}</span>
                        </p>
                    </div>
                )}
                <div className="form-group">
                    <textarea
                        type="text"
                        className="form-control mb-2"
                        name="observation"
                        rows="4"
                        ref={register({
                            required: false,
                        })}
                        placeholder="(Opcional) Escribe aquí una nota/recordatorio respecto al botellón, pago, cliente, etc."
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit(handleSave)}
                >
                    aceptar
                </button>
            </div>
        </Modal>
    );
};

const ModalChangeDeliveryUser = (props) => {
    const { visibleModalDU, setVisibleModalDU, onSave, handleClose, userDelivery, purchase } =
        props;
    const { handleSubmit, register } = useForm();

    const handleChangeDelivery = (form) => {
        const description = `ADMIN reasignó pedido #${purchase.id} de ${purchase.user.name} a ${
            userDelivery.label
        }(${userDelivery.value}). Motivo: ${form.description || 'N/A'}`;

        const data = {
            userId: userDelivery.value,
            action: visibleModalDU.action,
            description: description,
        };
        onSave(data);
    };

    return (
        <Modal
            open={visibleModalDU.visible}
            onClose={handleClose}
            classNames={{ modal: 'customModal' }}
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Cambio de repartidor
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <p style={{ fontWeight: 'bold' }}>¿Cual es el motivo del cambio?:</p>
                </div>
                <div className="form-group">
                    <div className="col-xl-6 col-sm-7 p-0">
                        <input
                            type="radio"
                            id="intentionalChange"
                            name="action"
                            value="INTENTIONAL_CHANGE"
                            checked={visibleModalDU.action === 'INTENTIONAL_CHANGE'}
                            onClick={() =>
                                setVisibleModalDU({
                                    ...visibleModalDU,
                                    action: 'INTENTIONAL_CHANGE',
                                })
                            }
                        />
                        &nbsp;&nbsp;
                        <label htmlFor="intentionalChange">INTENCIONAL</label>
                    </div>
                    <div className="col-xl-6 col-sm-7 p-0">
                        <input
                            type="radio"
                            id="unintentionalChange"
                            name="action"
                            value="UNINTENTIONAL_CHANGE"
                            checked={visibleModalDU.action === 'UNINTENTIONAL_CHANGE'}
                            onClick={() =>
                                setVisibleModalDU({
                                    ...visibleModalDU,
                                    action: 'UNINTENTIONAL_CHANGE',
                                })
                            }
                        />
                        &nbsp;&nbsp;
                        <label htmlFor="unintentionalChange">NO INTENCIONAL</label>
                    </div>
                </div>
                <div className="form-group">
                    <textarea
                        type="text"
                        className="form-control mb-2"
                        name="description"
                        rows="3"
                        ref={register({
                            required: false,
                        })}
                        placeholder="(Opcional) Escribe aquí una nota del cambio de pedido."
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit(handleChangeDelivery)}
                >
                    aceptar
                </button>
            </div>
        </Modal>
    );
};

const ModalEditDeliveryDetails = (props) => {
    const { purchase, visible, onSave, handleClose } = props;
    const { handleSubmit, register } = useForm({
        defaultValues: {
            change_cash: purchase.changeCash,
            payment_confirmation_id: purchase.payment_confirmation_id,
            preNote: purchase.preNote,
        },
    });

    const handleUpdate = (data) => {
        onSave({
            delivery_time: data.delivery_time,
            change_cash: data.change_cash,
            payment_confirmation_id: data.payment_confirmation_id,
            preNote: data.preNote,
        });
    };

    return (
        <Modal open={visible} onClose={handleClose} classNames={{ modal: 'customModal' }}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Detalles de entrega
                </h5>
            </div>
            <div className="modal-body">
                <div className="form">
                    <div className="form-group row">
                        <label className="col-xl-3 col-sm-4 mb-0">Hora de entrega:</label>
                        <div className="col-xl-4 col-sm-7 col-10 offset-1 p-0">
                            <input
                                className="form-control"
                                placeholder="Selecciona la hora"
                                name="delivery_time"
                                type="time"
                                ref={register({
                                    required: false,
                                })}
                            />
                        </div>
                    </div>

                    {purchase.paymentMethod && purchase.paymentMethod.accounts ? (
                        <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                                5 dígitos (pago móvil):
                            </label>
                            <div className="col-xl-4 col-sm-7 col-10 offset-1 p-0">
                                <input
                                    className="form-control"
                                    placeholder="Ejm: 12345"
                                    name="payment_confirmation_id"
                                    type="text"
                                    inputMode="numeric"
                                    ref={register({
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">Billete a pagar:</label>
                            <div className="col-xl-4 col-sm-7 col-10 offset-1 p-0">
                                <input
                                    className="form-control"
                                    placeholder="Ejm: 20.000 o 50.000"
                                    name="change_cash"
                                    type="text"
                                    inputMode="numeric"
                                    ref={register({
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>
                    )}
                    <hr />
                    <div className="form-group row">
                        <label className="col-xl-3 col-sm-4 mb-0">Nota pre-entrega:</label>
                        <div className="col-xl-4 col-sm-7 col-10 offset-1 p-0">
                            <input
                                className="form-control"
                                placeholder="Ejm: Dejar con el vigilante"
                                name="preNote"
                                type="text"
                                ref={register({
                                    required: false,
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit(handleUpdate)}
                >
                    modificar
                </button>
            </div>
        </Modal>
    );
};

function PurchaseDetail(props) {
    const [{ auth }] = useStateValue();
    const purchaseId = props.match.params.id;
    const [loaded, setLoaded] = useState(false);
    const [purchase, setPurchase] = useState([]);
    const [users, setUsers] = useState([]);
    const [userDelivery, setUserDelivery] = useState(null);
    const [loadingPurchase, setLoadingPurchase] = useState(false);
    const [visibleModalObservation, setVisibleModalObservation] = useState(false);
    const [visibleModalUpdateObservation, setVisibleModalUpdateObservation] = useState(false);
    const [visibleModalDeliveryDetails, setVisibleModalDeliveryDetails] = useState(false);
    const [visibleModalDU, setVisibleModalDU] = useState({
        visible: false,
        action: null,
        description: null,
    });

    useEffect(() => {
        fetchPurchase().then(async (purchase) => {
            const users = await fetchUsersByFranchise(purchase.franchise.id);
            setUsers(users);
            const purchasesClient = await fetchPurchasesByClient(purchase.client.id);
            const dataPurchase = {
                ...purchase,
                client: {
                    ...purchase.client,
                    purchases: purchasesClient,
                },
            };
            setPurchase(dataPurchase);
            setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (loaded) {
            let lat = null;
            let lng = null;
            if (purchase.client) {
                lat = purchase.address.latitude;
                lng = purchase.address.longitude;
            } else {
                lat = purchase.latitude;
                lng = purchase.longitude;
            }
            let map = new window.google.maps.Map(document.getElementById('map'), {
                center: { lat, lng },
                zoom: 16,
                mapTypeControl: false,
                streetViewControl: false,
            });
            new window.google.maps.Marker({
                position: { lat, lng },
                map,
            });
        }
    }, [loaded]);

    const fetchPurchase = async () => {
        const { data } = await api.get(`/purchase/${purchaseId}/detalle-admin`);
        const purchase = data.data;

        return purchase;
    };

    const fetchPurchasesByClient = async (clientId) => {
        const { data } = await api.get(`/client/${clientId}/purchases-admin`);
        const purchases = data.data;

        return purchases;
    };

    const fetchUsersByFranchise = async (franchiseId) => {
        const { data } = await api.get(`/franchise/${franchiseId}/users-availables`);
        let users = null;
        if (auth.user.role === 'ADMIN' || auth.user.role === 'FRANCHISE') {
            users = data.data;
        } else {
            users = data.data.filter((user) => user.role === 'DELIVERY');
        }

        return users;
    };

    const handleFetchPurchase = async () => {
        fetchPurchase().then(async (purchase) => {
            const purchasesClient = await fetchPurchasesByClient(purchase.client.id);
            const dataPurchase = {
                ...purchase,
                client: {
                    ...purchase.client,
                    purchases: purchasesClient,
                },
            };
            setPurchase(dataPurchase);
            setLoaded(true);
        });
    };

    const confirmPayment = async () => {
        setLoadingPurchase(true);
        try {
            await api.post(`/purchase/${purchase.id}/confirmar-pago`);
            await handleFetchPurchase();
            setLoadingPurchase(false);
            toast.success('Pago confirmado. Se ha enviado una notificación al cliente.');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const rejectOrder = async () => {
        setLoadingPurchase(true);
        try {
            await api.post(`/purchase/${purchase.id}/rechazar-pedido`, {
                observation: 'Incluir motivo',
            });
            await handleFetchPurchase();
            setLoadingPurchase(false);
            toast.info('Pago rechazado. Se ha enviado una notificación al cliente.');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const sendOrder = async () => {
        setLoadingPurchase(true);
        try {
            const purchaseData = { purchaseId: purchase.id };
            await api.post(`/purchase/enviar-pedido`, purchaseData);
            await handleFetchPurchase();
            setLoadingPurchase(false);
            toast.info('Pedido enviado. Se ha enviado una notificación al cliente.');
        } catch (error) {
            toast.error('Ha ocurrido un error. Intente de nuevo');
            setLoadingPurchase(false);
        }
    };

    const alertDelivery = async () => {
        setLoadingPurchase(true);
        // se busca en los deliveries disponibles
        // el id del usuario que tiene asignado el pedido
        const infoUserDelivery = users.find((user) => user.id === purchase.userId);
        try {
            await api.post(`/client/${purchase.client.id}/notification/aviso-entrega`, {
                orderId: purchase.id,
                expoTokenDelivery: infoUserDelivery.pushToken,
            });
            setLoadingPurchase(false);
            toast.info('Se ha enviado la notificación al cliente.');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const deliveredOrder = async (observation) => {
        setLoadingPurchase(true);
        try {
            setVisibleModalObservation(false);
            await api.post(`/purchase/${purchase.id}/pedido-entregado`, {
                observation: observation && observation.trim() !== '' ? observation : null,
                clientId: purchase.client.id,
            });
            if (observation && observation.trim() !== '') {
                await api.post(`/news`, {
                    description: observation,
                    orderId: purchase.id,
                });
            }
            await handleFetchPurchase();
            setLoadingPurchase(false);
            toast.success('Pedido entregado. Se ha enviado una notificación al cliente.');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const deleteOrder = async () => {
        setLoadingPurchase(true);
        try {
            await api.delete(`/purchase/${purchase.id}`, {
                observation: 'N/A',
            });
            // mostrar modal para preguntar si se envia notificación o no
            setLoadingPurchase(false);
            toast.warning('Pedido Eliminado con éxito');
            props.history.replace('/');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const assignOrder = async (dataDeliveryUser) => {
        if (userDelivery) {
            setLoadingPurchase(true);
            try {
                await api.post(`/purchase/${purchase.id}/user/${userDelivery.value}`);
                await handleDeliveryHistory(
                    auth.user.id,
                    dataDeliveryUser.action,
                    dataDeliveryUser.description,
                );
                await handleFetchPurchase();
                setVisibleModalDU({ visible: false });
                setLoadingPurchase(false);
                toast.success('Pedido asignado con éxito');
            } catch (error) {
                setLoadingPurchase(false);
                toast.error('Ha ocurrido un error. Intente de nuevo');
            }
        } else {
            toast.warning('Selecciona un usuario repartidor');
        }
    };

    const notifyExhangeOrder = async () => {
        const userToAssign = users.filter((user) => user.id !== auth.user.id)[0];
        setLoadingPurchase(true);
        try {
            await api.post(`/purchase/${purchase.id}/user/${userToAssign.id}`);
            await handleDeliveryHistory(
                auth.user.id,
                'INTENTIONAL_CHANGE',
                `${auth.user.name} no pudo entregar el pedido. Asignado para ${userToAssign.name}(${userToAssign.id})`,
            );
            await handleFetchPurchase();
            setLoadingPurchase(false);
            toast.success('El pedido ha sido asignado a otro delivery.');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const updateTransaction = async (observation, transactionId) => {
        setLoadingPurchase(true);
        try {
            setVisibleModalUpdateObservation(false);
            await api.put(`/purchase/${purchase.id}/transaction/${transactionId}`, {
                observation: observation && observation !== '' ? observation : null,
            });

            if (observation && observation.trim() !== '') {
                await api.post(`/news`, {
                    description: observation,
                    orderId: purchase.id,
                });
            }
            await handleFetchPurchase();
            setLoadingPurchase(false);
            toast.success('Transacción actualizada');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const handleDeliveryHistory = async (userId, action, description) => {
        const data = {
            userId,
            action,
            description,
        };

        try {
            await api.post(`/delivery_user_history`, data);
        } catch (e) {
            console.log(e);
        }
    };

    const updatePurchase = async (data) => {
        setLoadingPurchase(true);
        try {
            setVisibleModalDeliveryDetails(false);
            await api.put(`/purchase/${purchase.id}`, {
                delivery_time:
                    data.delivery_time && data.delivery_time !== '' ? data.delivery_time : null,
                changeCash: data.change_cash && data.change_cash !== '' ? data.change_cash : null,
                payment_confirmation_id:
                    data.payment_confirmation_id && data.payment_confirmation_id !== ''
                        ? data.payment_confirmation_id
                        : null,
                preNote: data.preNote && data.preNote !== '' ? data.preNote : null,
            });
            await handleFetchPurchase();
            setLoadingPurchase(false);
            toast.success('Pedido entregado. Se ha enviado una notificación al cliente.');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const renderBadge = (status) => {
        switch (status) {
            case 'Pedido Creado':
                return (
                    <span style={{ fontSize: 16, height: 25 }} className="badge badge-warning">
                        Creado
                    </span>
                );
            case 'Pago Confirmado':
                return (
                    <span style={{ fontSize: 16, height: 25 }} className="badge badge-info">
                        Pago Confirmado
                    </span>
                );
            case 'Pedido Enviado':
                return (
                    <span style={{ fontSize: 16, height: 25 }} className="badge badge-info">
                        Enviado
                    </span>
                );
            case 'Pedido Recibido':
                return (
                    <span style={{ fontSize: 16, height: 25 }} className="badge badge-success">
                        Recibido
                    </span>
                );
            case 'Pedido Rechazado':
                return (
                    <span style={{ fontSize: 16, height: 25 }} className="badge badge-danger">
                        Rechazado
                    </span>
                );
            case 'Pedido Entregado':
                return (
                    <span style={{ fontSize: 16, height: 25 }} className="badge badge-success">
                        Entregado
                    </span>
                );
            case 'Pedido Cancelado':
                return (
                    <span style={{ fontSize: 16, height: 25 }} className="badge badge-danger">
                        Cancelado
                    </span>
                );
            default:
                return null;
        }
    };

    const badgeSourceOrder = (label) => (
        <span style={{ fontSize: 16, height: 25 }} className="badge badge-info">
            {label}
        </span>
    );
    const renderMarkers = (map, maps, address) => {
        new maps.Marker({
            position: {
                lat: address.latitude,
                lng: address.longitude,
            },
            map,
            title: 'Prueba',
        });
    };

    const renderBitacora = () => {
        return purchase.transactions
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((item, index) => {
                return (
                    <div key={`bitacora-${index}`}>
                        <div className="d-flex justify-content-between mb-3">
                            <div>
                                <p>
                                    <strong>{item.name.split(' ')[1]}</strong>
                                </p>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <p>
                                    {date(item.createdAt)} {time(item.createdAt)}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            });
    };

    const sendPushNotification = async () => {
        setLoadingPurchase(true);
        try {
            const firstName = purchase.client.name.split(' ')[0];
            const data = {
                title: 'Pedido demorado ⌛',
                body: `${firstName}, pedimos disculpas. Tu pedido se demora unos minutos, te avisaremos una vez haya sido enviado.`,
                data: {
                    view: 'DetailOrder',
                    id: purchase.id,
                },
            };
            const dataNotificationTable = {
                title: 'Pedido demorado ⌛',
                description: `Tu pedido se demora unos minutos, te avisaremos una vez haya sido enviado.`,
                clientId: purchase.client.id,
                orderId: purchase.id,
                type: 'individual',
            };
            await api.post(`/notifications`, dataNotificationTable);
            await api.post(`/client/${purchase.client.id}/notification`, data);
            setLoadingPurchase(false);
            toast.success('Se envió la notificación al cliente');
        } catch (error) {
            setLoadingPurchase(false);
            toast.error('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const onCloseModalObservation = () => {
        setVisibleModalObservation(false);
    };

    const onCloseModalUpdateObservation = () => {
        setVisibleModalUpdateObservation(false);
    };

    const onCloseModalDeliveryDetails = () => {
        setVisibleModalDeliveryDetails(false);
    };

    const onCloseModalChangeDeliveryUser = () => {
        setVisibleModalDU({ ...visibleModalDU, visible: false });
    };

    const openModalChangeDelivery = () => {
        if (userDelivery) {
            setVisibleModalDU({ ...visibleModalDU, visible: true });
        } else {
            toast.error('Selecciona un usuario repartidor');
        }
    };

    // Ordenar las transacciones de cada pedido
    // para obtener la última transacción
    // en su defecto es la transacción "Pedido Entregado"
    const getLastTransaction = (purchase) => {
        const lastTransaction = purchase.transactions.reduce((a, b) =>
            a.createdAt > b.createdAt ? a : b,
        );

        return lastTransaction;
    };

    /**
     * Ordenar los pedidos para encontrar el pedido
     * más cercano (viejo) al pedido actual
     * @param {String} purchase Pedido actual
     * @param {Array} array Arreglo de todos los pedidos del cliente
     * @returns {Object} Retorna el pedido más cercano
     */
    const getClosestPurchase = (purchase, array) => {
        // Ordenar el arreglo de transacciones del más viejo al más nuevo
        const transactionsOfCurrentAddress = array.filter(
            (item) => item.address.id === purchase.address.id,
        );
        const sortedTransactions = transactionsOfCurrentAddress.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        );

        var closestPurchase =
            sortedTransactions
                .reverse()
                .find(({ createdAt }) => new Date(createdAt) < new Date(purchase.createdAt)) ||
            sortedTransactions[0];

        return closestPurchase;
    };

    /**
     * Retorna la observación del pedido anterior al actual
     * ej: si tiene 3 pedidos [1,4,8] y el pedido actual es 8
     * se va a retornar la observación del pedido 4
     * @param {Object} purchase Pedido actual
     * @returns {String} Observación del pedido anterior al actual
     */
    const getObservationClosestPurchase = (purchase) => {
        if (purchase.client.purchases.rows.length === 1) return null;
        const closestPurchase = getClosestPurchase(purchase, purchase.client.purchases.rows);

        const transaction = getLastTransaction(closestPurchase);
        if (!transaction.observation) return null;

        const date = new Date(transaction.createdAt);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const stringDate = `${day}/${month}/${year}`;

        return (
            <div>
                <p style={{ color: 'black', fontWeight: 'bold' }}>
                    {stringDate} - {closestPurchase.user.name.split(' ')[0]}
                </p>
                <p>
                    <strong style={{ color: 'red' }}>{transaction.observation}</strong>
                </p>
            </div>
        );
    };

    /**
     * Retorna la observación del pedido actual
     * @param {Object} purchase Pedido actual
     * @returns {String} Observación del pedido anterior al actual
     */
    const getObservationThisPurchase = (purchase) => {
        const transaction = getLastTransaction(purchase);

        if (!transaction.observation) return null;

        const date = new Date(transaction.createdAt);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const stringDate = `${day}/${month}/${year}`;
        return (
            <div>
                <p style={{ color: 'black', fontWeight: 'bold' }}>
                    {stringDate} - {purchase.user.name.split(' ')[0]}
                </p>
                <p>
                    <strong style={{ color: 'red' }}>{transaction.observation}</strong>
                </p>
            </div>
        );
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    let total = 0;

    if (purchase.products.length > 0) {
        total = purchase.products.reduce(
            (a, b) => a + b.purchaseProducts.quantity * b.purchaseProducts.unitPrice,
            0,
        );
    } else if (purchase.paymentMethod && purchase.products.length === 0) {
        total =
            Number(purchase.quantity) * Number(purchase.unity_amount_refill) +
            Number(purchase.quantity_new_bottles) * Number(purchase.amount_new_bottle) +
            Number(purchase.quantity_starterkit) * Number(purchase.amount_starterkit) +
            Number(purchase.quantity_dispenser) * Number(purchase.amount_dispenser);
    }

    if (purchase.firstOrderFree) {
        total = total - Number(purchase.unity_amount_refill);
    }

    const pedidoHaSidoEnviado =
        purchase.transactions.find(
            (item) =>
                item.name === 'Pedido Enviado' ||
                item.name === 'Pedido Rechazado' ||
                item.name === 'Pedido Recibido',
        ) !== undefined;

    const pedidoHaSidoEntregado = purchase.transactions.find(
        (item) => item.name === 'Pedido Entregado',
    );

    const pedidoHaSidoCancelado = purchase.transactions.find(
        (item) => item.name === 'Pedido Cancelado',
    );

    const pedidoHaSidoRechazado = purchase.transactions.find(
        (item) => item.name === 'Pedido Rechazado',
    );

    const lastTransaction = purchase.transactions.reduce((a, b) =>
        a.createdAt > b.createdAt ? a : b,
    );

    const getFirstTransaction = (purchase) =>
        purchase.transactions.reduce((a, b) => (b.createdAt > a.createdAt ? a : b));

    const isAdmin = auth.user.role === 'ADMIN' || auth.user.role === 'FRANCHISE';
    const productRecargas = purchase.products.find((item) => item.name === 'Recarga');

    // calcular el monto del cupón para restarlo al monto total
    if (purchase.coupon) {
        total = total - Number(purchase.couponAmount);
    }

    // Observación dada por el delivery en el pedido anterior
    const observationClosestPurchase = getObservationClosestPurchase(purchase);
    const observationThisPurchase = getObservationThisPurchase(purchase);

    // bandera que el usuario activo (usando la interfaz)
    // es el mismo que tiene asignado el pedido
    const isUserDelivery = purchase.userId === auth.user.id;

    // bandera para mostrar a primera hora
    const hourPurchase = new Date(getFirstTransaction(purchase).createdAt).getHours();

    const firstMomentNextDate = () => {
        const purchaseWeekday = new Date(purchase.createdAt).getDay();
        const deliveryWeekday = purchase.delivery.weekday;

        if (deliveryWeekday) {
            if (purchaseWeekday === deliveryWeekday && hourPurchase < 8) {
                return true;
            } else if (purchaseWeekday != deliveryWeekday) {
                return true;
            }
        } else {
            if (hourPurchase > 8 && hourPurchase < 18) {
                // no se hace nada
                return false;
            } else {
                // horario fuera del habitual
                return true;
            }
        }
        return false;
    };

    const phoneParsed = purchase.client.phone_number;
    const whatsappUrl = () =>
        `https://wa.me/${phoneParsed}?text=Hola, soy ${
            purchase.user.name.split(' ')[0]
        } de Aguaman.%0a%0aEstoy%20en%20la%20puerta%20para%20entregarte%20el%20pedido.`;

    const whatsappUrlObservation = () =>
        `https://wa.me/${phoneParsed}?text=Hola, Sr(a) ${
            purchase.client.name.split(' ')[0]
        }. Dejaré la siguiente nota relacionado su pedido en nuestro sistema: %0A%0A${
            lastTransaction.observation
        }. %0A%0ASi tiene alguna observación con esta nota por favor me la hace saber. Gracias`;

    return (
        <Fragment>
            <div className="container-fluid container-order-detail pt-3">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="card">
                            <div className="card-body" style={{ paddingBottom: 0 }}>
                                {!pedidoHaSidoCancelado && purchase.user && (
                                    <div className="d-flex justify-content-start align-items-center">
                                        <img
                                            src={purchase.user.profile_img}
                                            height={50}
                                            width={50}
                                            style={{ borderRadius: 40 }}
                                        />
                                        &nbsp;&nbsp;
                                        <p style={{ color: '#444', fontWeight: 'bold' }}>
                                            {purchase.user.name}
                                        </p>
                                    </div>
                                )}
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 style={{ margin: 0 }} className="d-flex align-items-center">
                                        #<strong>{purchase.id}</strong>&nbsp;
                                        {badgeSourceOrder(purchase.requestedFrom)}&nbsp;
                                        {emojiRequested[purchase.requestedFrom]}
                                    </h4>
                                    {renderBadge(lastTransaction.name)}
                                </div>
                                <hr />
                                <div className="order-box">
                                    {/* <div className="title-box">
                                        <h4>Item</h4>
                                        <h4>Costo</h4>
                                    </div> */}
                                    <ul className="sub-total">
                                        {purchase.products.length > 0
                                            ? purchase.products.map((product, index) => (
                                                  <li
                                                      className="d-flex justify-content-between"
                                                      key={`product-purchase-${index}`}
                                                  >
                                                      <div className="description-item-checkout">
                                                          <div>
                                                              <p>
                                                                  <strong style={{ fontSize: 20 }}>
                                                                      {
                                                                          product.purchaseProducts
                                                                              .quantity
                                                                      }
                                                                  </strong>
                                                                  &nbsp;
                                                                  {product.name}
                                                              </p>
                                                          </div>
                                                      </div>
                                                      <div className="total-item-checkout">
                                                          {purchase.paymentMethod &&
                                                          !purchase.subscription ? (
                                                              <p style={{ fontWeight: 500 }}>
                                                                  {purchase.paymentMethod.currency}{' '}
                                                                  {currency(
                                                                      product.purchaseProducts
                                                                          .quantity *
                                                                          Number(
                                                                              product
                                                                                  .purchaseProducts
                                                                                  .unitPrice,
                                                                          ),
                                                                  )}
                                                              </p>
                                                          ) : (
                                                              'N/A'
                                                          )}
                                                      </div>
                                                  </li>
                                              ))
                                            : null}
                                        {purchase.products.length === 0 &&
                                            renderOldProducts(purchase)}
                                        {purchase.firstOrderFree && (
                                            <li className="d-flex justify-content-between">
                                                <div className="description-item-checkout">
                                                    <p style={{ color: 'green' }}>
                                                        <strong>Recarga gratis</strong>
                                                    </p>
                                                </div>
                                                <div className="total-item-checkout">
                                                    <strong
                                                        className="count"
                                                        style={{ color: 'green' }}
                                                    >
                                                        - {purchase.paymentMethod.currency}{' '}
                                                        {currency(purchase.unity_amount_refill)}
                                                    </strong>
                                                </div>
                                            </li>
                                        )}
                                        {purchase.coupon && (
                                            <li className="d-flex justify-content-between">
                                                <div className="description-item-checkout">
                                                    <p style={{ color: 'green' }}>
                                                        Cupón de{' '}
                                                        {purchase.coupon.type === 'PERCENTAGE'
                                                            ? Number(purchase.coupon.amount_usd)
                                                            : currency(purchase.couponAmount)}{' '}
                                                        {purchase.coupon.type === 'PERCENTAGE'
                                                            ? '%'
                                                            : purchase.paymentMethod.currency}
                                                    </p>
                                                </div>
                                                <div className="total-item-checkout">
                                                    <strong
                                                        className="count"
                                                        style={{ color: 'green' }}
                                                    >
                                                        - {purchase.paymentMethod.currency}{' '}
                                                        {currency(purchase.couponAmount)}
                                                    </strong>
                                                </div>
                                            </li>
                                        )}
                                        {purchase.paymentMethod && !purchase.subscription ? (
                                            <li style={{ paddingTop: 16 }}>
                                                <h4>
                                                    <strong>Total</strong>
                                                </h4>
                                                <h4>
                                                    <strong className="count">
                                                        {purchase.paymentMethod.currency}{' '}
                                                        {currency(total)}
                                                    </strong>
                                                </h4>
                                            </li>
                                        ) : null}
                                        {purchase.coupon ? (
                                            <li style={{ paddingTop: 16 }}>
                                                <p>
                                                    Cupón: <strong>{purchase.coupon.code}</strong>
                                                </p>
                                            </li>
                                        ) : null}
                                        {purchase.subscription ? (
                                            <li
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 8,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {pedidoHaSidoEntregado ? (
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            height: 25,
                                                        }}
                                                        className="badge badge-success"
                                                    >
                                                        Le quedan:{' '}
                                                        {purchase.subscription.availability}{' '}
                                                        recargas a favor
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            height: 25,
                                                            color: 'black',
                                                        }}
                                                        className="badge badge-warning"
                                                    >
                                                        Tiene:{' '}
                                                        {Number(
                                                            purchase.subscription.availability,
                                                        ) + Number(purchase.quantity)}{' '}
                                                        recargas a favor
                                                    </span>
                                                )}
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 mb-3">
                            {loadingPurchase && (
                                <div
                                    className="d-flex justify-content-center"
                                    style={{ height: 150 }}
                                >
                                    <Loader />
                                </div>
                            )}
                            {!loadingPurchase &&
                            !pedidoHaSidoEnviado &&
                            !pedidoHaSidoCancelado &&
                            (isUserDelivery || auth.user.role === 'ADMIN') ? (
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-lg btn-primary" onClick={sendOrder}>
                                        enviar pedido
                                    </button>
                                </div>
                            ) : null}
                            {!loadingPurchase &&
                            pedidoHaSidoEnviado &&
                            !pedidoHaSidoEntregado &&
                            !pedidoHaSidoRechazado ? (
                                <div className="d-flex justify-content-center">
                                    {purchase.client.expoToken && (
                                        <button
                                            className="btn btn-lg btn-warning"
                                            onClick={alertDelivery}
                                        >
                                            <i className="fa fa-bell"></i>
                                        </button>
                                    )}
                                    &nbsp;&nbsp;
                                    <a
                                        className="btn btn-lg btn-info"
                                        href={`tel:+${purchase.client.phone_number}`}
                                    >
                                        <i className="fa fa-phone"></i>
                                    </a>
                                    &nbsp;&nbsp;
                                    <a
                                        className="btn btn-lg btn-success btn-whatsapp"
                                        href={whatsappUrl()}
                                        target="_blank"
                                    >
                                        <i className="fa fa-whatsapp"></i>
                                    </a>
                                </div>
                            ) : null}
                            {!loadingPurchase &&
                            pedidoHaSidoEnviado &&
                            !pedidoHaSidoEntregado &&
                            !pedidoHaSidoRechazado ? (
                                <div className="d-flex justify-content-center mt-4">
                                    <button
                                        className="btn btn-lg btn-success"
                                        onClick={() => setVisibleModalObservation(true)}
                                    >
                                        entregado
                                    </button>
                                </div>
                            ) : null}
                            {auth.user.role === 'ADMIN' &&
                            !purchase.user &&
                            !pedidoHaSidoEnviado ? (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row align-items-end">
                                            <div className="col-lg-6 col-12">
                                                <label>Asignar pedido:</label>
                                                <div>
                                                    <Select
                                                        name="userDeliveryId"
                                                        placeholder="Selecciona el repartidor"
                                                        onChange={(value) => setUserDelivery(value)}
                                                        value={userDelivery}
                                                        options={users.map((user) => {
                                                            return {
                                                                value: user.id,
                                                                label: user.name,
                                                            };
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 text-center mt-3">
                                                <button
                                                    className="btn btn-lg btn-success"
                                                    onClick={assignOrder}
                                                >
                                                    asignar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'end',
                                            marginBottom: 8,
                                        }}
                                    >
                                        <Clock height="30" width="25" />
                                        &nbsp;&nbsp;
                                        <p>
                                            {purchase.delivery_time ? (
                                                <strong>
                                                    {getTimeFormat(purchase.delivery_time)}
                                                </strong>
                                            ) : (
                                                <strong>
                                                    {firstMomentNextDate()
                                                        ? '8:00am'
                                                        : time(
                                                              getFirstTransaction(purchase)
                                                                  .createdAt,
                                                          )}{' '}
                                                    - Express
                                                </strong>
                                            )}
                                        </p>
                                    </div>

                                    {productRecargas && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'end',
                                                marginBottom: 8,
                                            }}
                                        >
                                            <img src={IconoBotellon} alt="Botellones" height="40" />
                                            &nbsp;&nbsp;
                                            <p>
                                                <strong>
                                                    {getBottlesPurchase(
                                                        productRecargas.purchaseProducts.quantity,
                                                        purchase.address.newBottles,
                                                    )}
                                                </strong>
                                            </p>
                                        </div>
                                    )}
                                    {!purchase.subscription && purchase.changeCash && (
                                        <p>
                                            Vueltos para:{' '}
                                            <strong>
                                                {purchase.paymentMethod.currency}
                                                {currency(purchase.changeCash)}
                                            </strong>
                                        </p>
                                    )}
                                    {/*!purchase.subscription && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                marginTop: 8,
                                            }}
                                        >
                                            <p>
                                                Pago:{' '}
                                                <strong>
                                                    {purchase.paymentMethod.name.split('(')[0]}
                                                </strong>
                                            </p>
                                        </div>
                                        )*/}
                                    {observationThisPurchase ||
                                    (observationClosestPurchase && !pedidoHaSidoEntregado) ? (
                                        <hr />
                                    ) : null}

                                    {pedidoHaSidoEntregado && (
                                        <div style={{ marginTop: 16 }}>
                                            <button
                                                className="btn btn-xs"
                                                onClick={() =>
                                                    setVisibleModalUpdateObservation(true)
                                                }
                                            >
                                                Agregar/Modificar observación
                                            </button>
                                        </div>
                                    )}

                                    {observationThisPurchase && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                marginTop: 8,
                                            }}
                                        >
                                            {observationThisPurchase}
                                        </div>
                                    )}
                                    {observationClosestPurchase && !pedidoHaSidoEntregado && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                marginTop: 8,
                                            }}
                                        >
                                            <p style={{ fontSize: 14, color: 'red' }}>
                                                <strong>{observationClosestPurchase}</strong>
                                            </p>
                                        </div>
                                    )}
                                    {purchase.address.observation && (
                                        <p className="text-danger">
                                            <strong>{purchase.address.observation}</strong>
                                        </p>
                                    )}
                                    {isAdmin && (
                                        <div style={{ position: 'absolute', top: 10, right: 10 }}>
                                            <button
                                                className="btn btn-sm"
                                                onClick={() => setVisibleModalDeliveryDetails(true)}
                                            >
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                        </div>
                                    )}
                                    {purchase.preNote && (
                                        <div className="d-flex justify-content-center align-items-center mt-3">
                                            <p
                                                style={{
                                                    fontSize: 18,
                                                    color: 'black',
                                                    backgroundColor: '#ffbc58',
                                                    borderRadius: 5,
                                                    padding: '0 .3em',
                                                }}
                                            >
                                                <strong>{purchase.preNote}</strong>
                                            </p>
                                        </div>
                                    )}
                                    {pedidoHaSidoEntregado && observationThisPurchase && (
                                        <div style={{ marginTop: 16 }}>
                                            <a
                                                className="btn btn-xs btn-info"
                                                href={whatsappUrlObservation(
                                                    observationThisPurchase,
                                                )}
                                            >
                                                Enviar observación al cliente
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {purchase.paymentMethod && purchase.paymentMethod.accounts ? (
                            <div className="card">
                                <div className="card-body">
                                    <div className="clearfix"></div>
                                    <h4>Método de pago</h4>
                                    <hr />
                                    <div>
                                        <p>{purchase.paymentMethod.name}</p>
                                        {purchase.paymentMethod.accounts ? (
                                            <div>
                                                <p>
                                                    Últimos 5 dígitos:{' '}
                                                    <strong>
                                                        {purchase.payment_confirmation_id}
                                                    </strong>
                                                </p>
                                            </div>
                                        ) : null}
                                    </div>
                                    {purchase.paymentMethod.accounts &&
                                    lastTransaction.name === 'Pedido Creado' &&
                                    lastTransaction.name !== 'Pedido Rechazado' ? (
                                        <div className="mt-4 d-flex justify-content-between">
                                            <button
                                                className="btn btn-success"
                                                onClick={confirmPayment}
                                            >
                                                Confirmar
                                            </button>
                                            <button
                                                className="btn btn-danger"
                                                onClick={rejectOrder}
                                            >
                                                Rechazar
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="clearfix"></div>

                        {purchase.client ? (
                            <InfoClient
                                data={purchase}
                                renderMarkers={renderMarkers}
                                auth={auth}
                                renderBitacora={renderBitacora}
                                purchase={purchase}
                                pedidoHaSidoCancelado={pedidoHaSidoCancelado}
                                pedidoHaSidoEnviado={pedidoHaSidoEnviado}
                                userDelivery={userDelivery}
                                setUserDelivery={setUserDelivery}
                                users={users}
                                assignOrder={assignOrder}
                                openModalChange={openModalChangeDelivery}
                            />
                        ) : (
                            <InfoQuickClient data={purchase} renderMarkers={renderMarkers} />
                        )}
                    </div>
                    <div className="col-lg-6 col-sm-12"></div>
                    <div className="col-lg-6 col-sm-12">
                        <LastPurchases purchases={purchase.client.purchases.rows} />
                    </div>
                </div>
                <div className="row">
                    {isAdmin &&
                    !loadingPurchase &&
                    !pedidoHaSidoEntregado &&
                    purchase.client.expoToken &&
                    !pedidoHaSidoCancelado ? (
                        <div className="col-lg-12 col-sm-12 mb-4 d-flex justify-content-center">
                            <button
                                className="btn btn-sm btn-warning"
                                onClick={sendPushNotification}
                            >
                                notificar pedido demorado
                            </button>
                        </div>
                    ) : null}
                </div>
                <div className="row">
                    {!loadingPurchase &&
                    isUserDelivery &&
                    users.length > 1 &&
                    !pedidoHaSidoEnviado &&
                    !pedidoHaSidoEntregado &&
                    !pedidoHaSidoCancelado ? (
                        <div className="col-lg-12 col-sm-12 mb-4 d-flex justify-content-center">
                            <button className="btn btn-sm btn-danger" onClick={notifyExhangeOrder}>
                                no puedo entregar este pedido
                            </button>
                        </div>
                    ) : null}
                </div>
                <div className="row">
                    {isAdmin && !loadingPurchase && !pedidoHaSidoEnviado ? (
                        <div className="col-lg-12 col-sm-12 mb-4 d-flex justify-content-center">
                            <button className="btn btn-sm btn-danger" onClick={deleteOrder}>
                                eliminar pedido
                            </button>
                        </div>
                    ) : null}
                </div>
                <ModalObservation
                    visible={visibleModalObservation}
                    onSave={deliveredOrder}
                    handleClose={onCloseModalObservation}
                    observationClosestPurchase={observationClosestPurchase}
                />
                {pedidoHaSidoEntregado && (
                    <ModalObservation
                        visible={visibleModalUpdateObservation}
                        onSave={updateTransaction}
                        handleClose={onCloseModalUpdateObservation}
                        observationClosestPurchase={observationThisPurchase}
                        transactionId={pedidoHaSidoEntregado.id}
                    />
                )}
                <ModalEditDeliveryDetails
                    purchase={purchase}
                    visible={visibleModalDeliveryDetails}
                    onSave={updatePurchase}
                    handleClose={onCloseModalDeliveryDetails}
                    observationClosestPurchase={observationClosestPurchase}
                />
                <ModalChangeDeliveryUser
                    purchase={purchase}
                    userDelivery={userDelivery}
                    visibleModalDU={visibleModalDU}
                    setVisibleModalDU={setVisibleModalDU}
                    onSave={assignOrder}
                    handleClose={onCloseModalChangeDeliveryUser}
                    observationClosestPurchase={observationClosestPurchase}
                />
            </div>
        </Fragment>
    );
}

const InfoClient = (props) => {
    const {
        data,
        auth,
        renderBitacora,
        purchase,
        pedidoHaSidoCancelado,
        pedidoHaSidoEnviado,
        userDelivery,
        setUserDelivery,
        users,
        assignOrder,
        openModalChange,
    } = props;
    const destination = `${data.address.latitude},${data.address.longitude}`;

    const getStatusClient = (count) => {
        if (count === 1) {
            return (
                <span style={{ fontSize: 16, height: 25 }} className="badge badge-success">
                    Nuevo
                </span>
            );
        } else if (count > 1 && count < 5) {
            return (
                <span style={{ fontSize: 16, height: 25 }} className="badge badge-info">
                    Principiante
                </span>
            );
        } else if ((count) => 5) {
            return (
                <span style={{ fontSize: 16, height: 25 }} className="badge badge-success">
                    Super Cliente
                </span>
            );
        }
    };

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 style={{ margin: 0 }}>
                            {auth.user.role !== 'DELIVERY' ? (
                                <Link to={`${process.env.PUBLIC_URL}/clientes/${data.client.id}`}>
                                    <strong>{data.client.name}</strong>
                                </Link>
                            ) : (
                                data.client.name
                            )}
                        </h4>
                        <div>{getStatusClient(data.client.purchases.count)}</div>
                    </div>
                    <hr />
                    <p>
                        <strong>{data.client.tokenInvitation}</strong>
                    </p>
                    <p>
                        {data.address.street1}, {data.address.street2}
                    </p>
                    <p style={{ fontSize: '1.1em' }} className="mt-2">
                        <a href={`tel:+${data.client.phone_number}`}>
                            Llamar a {data.client.name.split(' ')[0]}
                        </a>
                    </p>
                    <br />
                    <div id="map" style={{ width: '100%', height: 300 }}></div>
                    <div className="d-flex justify-content-center mt-1">
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${destination}`}
                            className="btn btn-sm"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Abrir en Google Maps
                        </a>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="clearfix"></div>
                    <h4>Bitácora</h4>
                    <hr />
                    {renderBitacora()}
                </div>
            </div>
            {purchase.user && (
                <div className="card">
                    <div className="card-body">
                        <div className="clearfix"></div>
                        <h4>Repartidor</h4>
                        <hr />
                        <div className="d-flex justify-content-start align-items-center">
                            <p>{purchase.user.name}</p>
                        </div>
                        <p>
                            <strong>{purchase.user.role}</strong>
                        </p>
                        {!pedidoHaSidoCancelado && auth.user.role === 'ADMIN' && (
                            <div>
                                <br />
                                <div className="row align-items-end">
                                    <div className="col-lg-6 col-12">
                                        <label>Cambiar repartidor:</label>
                                        <div>
                                            <Select
                                                name="userDeliveryId"
                                                placeholder="Selecciona el repartidor"
                                                onChange={(value) => setUserDelivery(value)}
                                                value={userDelivery}
                                                options={users.map((user) => {
                                                    return {
                                                        value: user.id,
                                                        label: user.name,
                                                    };
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 text-center mt-3">
                                        <button
                                            className="btn btn-lg btn-success"
                                            onClick={openModalChange}
                                        >
                                            asignar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const LastPurchases = (props) => {
    const { purchases } = props;
    return (
        <div className="card">
            <div className="card-body">
                <div className="clearfix"></div>
                <h4>Últimos 2 pedidos</h4>
                <hr />
                {purchases.slice(0, 2).map((purchase, index) => {
                    return (
                        <div key={`lastPurchase-${index}`}>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div>
                                    <p style={{ fontSize: '1.1em' }} className="mt-2">
                                        <a
                                            href={`${process.env.PUBLIC_URL}/recargas/detalle/${purchase.id}`}
                                        >
                                            {utils.renderTextPedido(purchase)} - {purchase.id}
                                        </a>
                                    </p>
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    <p>{date(purchase.createdAt)}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const InfoQuickClient = (props) => {
    const { data, renderMarkers } = props;
    const destination = `${data.latitude},${data.longitude}`;
    return (
        <div>
            <p>
                <strong>{data.nameQuick}</strong>
            </p>
            <p>{data.addressQuick}</p>
            <p>{data.phoneQuick}</p>
            <p style={{ textAlign: 'right' }}>Cliente sin registrarse</p>
            <br />
            <div className="d-flex justify-content-center">
                <a
                    href={`https://www.google.com/maps/search/?api=1&query=${destination}`}
                    className="btn btn-sm"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Abrir en Google Maps
                </a>
            </div>
            <div id="map" style={{ width: '100%', height: 300 }}></div>
        </div>
    );
};

const renderOldProducts = (purchase) => {
    return (
        <>
            {purchase.quantity > 0 ? (
                <li className="d-flex justify-content-between">
                    <div className="description-item-checkout">
                        <div>
                            <p>
                                <strong style={{ fontSize: 24 }}>{purchase.quantity}</strong>
                                Recarga{purchase.quantity > 1 && 's'}
                            </p>
                        </div>
                    </div>
                    <div className="total-item-checkout">
                        {purchase.paymentMethod ? (
                            <strong>
                                {purchase.paymentMethod.currency}{' '}
                                {currency(purchase.quantity * Number(purchase.unity_amount_refill))}
                            </strong>
                        ) : (
                            'N/A'
                        )}
                    </div>
                </li>
            ) : null}
            {purchase.quantity_new_bottles > 0 ? (
                <li className="d-flex justify-content-between">
                    <div className="description-item-checkout">
                        <div>
                            <p>
                                <strong style={{ fontSize: 24 }}>
                                    {purchase.quantity_new_bottles}
                                </strong>{' '}
                                Botellon
                                {purchase.quantity_new_bottles > 1 && 'es'} nuevo
                                {purchase.quantity_new_bottles > 1 && 's'}{' '}
                            </p>
                        </div>
                    </div>
                    <div className="total-item-checkout">
                        <strong>
                            {purchase.paymentMethod.currency}{' '}
                            {currency(purchase.quantity_new_bottles * purchase.amount_new_bottle)}
                        </strong>
                    </div>
                </li>
            ) : null}
            {purchase.quantity_starterkit > 0 ? (
                <li className="d-flex justify-content-between">
                    <div className="description-item-checkout">
                        <div>
                            <p>
                                <strong style={{ fontSize: 24 }}>
                                    {purchase.quantity_starterkit}
                                </strong>{' '}
                                Kit de Inicio (Botellón nuevo, dispensador y recarga)
                            </p>
                        </div>
                    </div>
                    <div className="total-item-checkout">
                        <strong>
                            {purchase.paymentMethod.currency}{' '}
                            {currency(purchase.quantity_starterkit * purchase.amount_starterkit)}
                        </strong>
                    </div>
                </li>
            ) : null}
            {purchase.quantity_dispenser > 0 ? (
                <li className="d-flex justify-content-between">
                    <div className="description-item-checkout">
                        <p>
                            <strong style={{ fontSize: 24 }}>{purchase.quantity_dispenser}</strong>{' '}
                            Dispensador
                            {purchase.quantity_dispenser > 1 && 'es'}{' '}
                        </p>
                    </div>
                    <div className="total-item-checkout">
                        <strong>
                            {purchase.paymentMethod.currency}{' '}
                            {currency(purchase.quantity_dispenser * purchase.amount_dispenser)}
                        </strong>
                    </div>
                </li>
            ) : null}
        </>
    );
};

export default PurchaseDetail;
