import React, { useEffect, useReducer, Fragment, useState } from 'react';
import { Chart } from 'react-google-charts';
import ReactTable from 'react-table';
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import Loader from '../common/loader';
import api from '../../config/axios';
import { dateQuery } from '../../utils/date';
import Breadcrumb from '../common/breadcrumb';
import { useStateValue } from '../../store';
import {
    LineOptionsRevenue,
    LineOptionsUsers,
    columnsFranchisesTable,
    columnsUsersTable,
    columnsDeliveryUsersTable,
} from './utils';

const getDatesRange = (type) => {
    let dates = { startDate: null, endDate: null };
    const startDate = new Date();
    const endDate = new Date();

    switch (type) {
        case 'currently-month':
            dates.startDate = dateQuery(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
            dates.endDate = dateQuery(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0));
            break;
        case 'last-month':
            dates.startDate = dateQuery(
                new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1),
            );
            dates.endDate = dateQuery(new Date(endDate.getFullYear(), endDate.getMonth(), 0));
            break;
        case 'all':
            dates.startDate = dateQuery(new Date(2020, 0, 1));
            dates.endDate = dateQuery(endDate);
            break;
    }
    return dates;
};

function Dashboard(props) {
    const [{ auth }] = useStateValue();
    const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {
        resolved: false,
        loading: true,
        data: {
            clients: null,
            franchises: null,
            users: null,
            purchasesGrouped: null,
            salesV2: null,
            allPurchases: null,
            deliveryUserHistory: null,
        },
        error: null,
    });
    const [filters, setFilters] = useReducer((s, a) => ({ ...s, ...a }), {
        currency: 'COP',
        franchise: auth.user.franchiseId || '5e95f51f-5b66-4c58-b2af-7a54e8b1df1e',
        range: 'currently-month',
    });
    const [modalChange, setModalChange] = useState({
        visible: false,
        data: { user: null, value: null },
    });

    const date = new Date();
    // https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth
    // getMonth() returns 0-11
    const currentMonth = date.getMonth() + 1;
    const firstYearDay = new Date(date.getFullYear(), 0, 1);
    const lastYearDay = new Date(date.getFullYear(), 11, 31);

    const franchiseId = filters.franchise;

    useEffect(() => {
        if (auth.user.role === 'DELIVERY') {
            return props.history.replace(`/recargas`);
        }
        if (auth.user.role !== 'ADMIN') {
            return props.history.replace(`/dashboard/${auth.user.franchiseId}`);
        }
    }, []);

    useEffect(() => {
        const { startDate, endDate } = getDatesRange(filters.range);
        if (true) {
            setState({
                resolved: false,
                loading: true,
                data: {
                    ...state.data,
                    clients: null,
                    franchises: null,
                    allPurchases: null,
                },
                error: null,
            });

            Promise.all([
                fetchClients(firstYearDay, lastYearDay, franchiseId),
                fetchFranchises(),
                fetchAllPurchases(firstYearDay, lastYearDay),
                fetchUsers(),
                fetchDeliveryDaily(franchiseId),
            ])
                .then((values) => {
                    const data = {
                        ...state.data,
                        clients: values[0],
                        franchises: values[1],
                        allPurchases: values[2],
                        users: values[3].filter((e) => e.role !== 'ADMIN'),
                        deliveryUserHistory: values[4],
                    };
                    setState({
                        ...state,
                        loading: false,
                        resolved: true,
                        data,
                    });
                })
                .catch((error) => console.log(error));
        }
    }, [filters.franchise, filters.currency, filters.range]);

    /**
     * Fetch franchises
     * @returns Object
     */
    const fetchFranchises = async () => {
        const { data } = await api.get(`/franchise`);
        const franchises = data.data.rows;

        return franchises;
    };

    /**
     * Fetch franchises
     * @returns Object
     */
    const fetchUsers = async () => {
        const { data } = await api.get(`/user`);
        const users = data.data.rows;

        return users;
    };

    /**
     * Fetch new clients per month
     * @param {String} startDate YYYY-MM-DD
     * @param {String} endDate YYYY-MM-DD
     * @returns Object
     */
    const fetchClients = async (startDate, endDate, franchiseId) => {
        const { data } = await api.get(
            `/client/new-clients-per-month?startDate=${startDate}&endDate=${endDate}&franchiseId=${franchiseId}`,
        );
        const clients = data.data;

        return clients;
    };

    /**
     * Fetch all delivered orders per month
     * @param {String} startDate YYYY-MM-DD
     * @param {String} endDate YYYY-MM-DD
     * @returns Object
     */
    const fetchAllPurchases = async (startDate, endDate) => {
        const startDateISO = startDate.toISOString().split('T')[0];
        const endDateISO = endDate.toISOString().split('T')[0];

        const { data } = await api.get(
            `/purchase/all-sum?startDate=${startDateISO}&endDate=${endDateISO}`,
        );
        const allPurchases = data.data;
        allPurchases.sort((a, b) => a.monthNumber - b.monthNumber);

        return allPurchases;
    };

    /**
     * Fetch franchises
     * @returns Object
     */
    const fetchDeliveryDaily = async (franchiseId) => {
        const { data } = await api.get(`/delivery_user_history/franchise/${franchiseId}`);
        const result = data.data.map((e) => ({ ...e, franchiseId }));

        return result;
    };

    const handleDeliveryChanges = async (key, data) => {
        setModalChange({ ...modalChange, data: { ...modalChange.data, [key]: data } });
    };

    const submitDeliveryChange = async (userId, value) => {
        try {
            const { data } = await api.put(`/user/${userId}/changes/${value}`);

            if (data.success) {
                window.location.reload();
            }
        } catch (e) {
            alert('ERROR submitDeliveryChange()');
        }
    };

    const onCloseModalChange = () => setModalChange({ ...modalChange, visible: false });

    if (state.loading)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const allPurchasesParsedByMonth = [
        [{ label: 'Mes' }, { type: 'number', label: 'Unidades' }],
        ...state.data.allPurchases.map((item) => [item.month, Number(item.quantity)]),
    ];

    const newClientsParsedByMonth = [
        [{ label: 'Mes' }, { type: 'number', label: 'Clientes' }],
        ...state.data.clients.map((item) => [item.month, Number(item.newClients)]),
    ];

    return (
        <Fragment>
            <Breadcrumb title="Dashboard" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-2">
                        <h3>Repartidores</h3>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <ReactTable
                                    data={state.data.deliveryUserHistory}
                                    class="-striped -highlight"
                                    multiSelectOption={false}
                                    defaultPageSize={state.data.deliveryUserHistory.length}
                                    showPageSizeOptions={false}
                                    showPagination={false}
                                    options={false}
                                    columns={columnsDeliveryUsersTable}
                                    manual
                                />
                                <br />
                                <div className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn-xs"
                                        onClick={() =>
                                            setModalChange({ ...modalChange, visible: true })
                                        }
                                    >
                                        Editar cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-6 xl-50">
                        <div className="card order-graph sales-carousel">
                            <div className="card-header">
                                <h4>Unidades totales</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="small-chartjs">
                                            <div
                                                className="flot-chart-placeholder"
                                                id="simple-line-chart-sparkline-2"
                                            >
                                                <Chart
                                                    height={'300px'}
                                                    width={'100%'}
                                                    chartType="LineChart"
                                                    loader={<div>Cargando Datos</div>}
                                                    data={allPurchasesParsedByMonth}
                                                    options={LineOptionsRevenue}
                                                    legend_toggle
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 xl-50">
                        <div className="card order-graph sales-carousel">
                            <div className="card-header">
                                <h4>Clientes nuevos</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="small-chartjs">
                                            <div
                                                className="flot-chart-placeholder"
                                                id="simple-line-chart-sparkline-2"
                                            >
                                                <Chart
                                                    height={'300px'}
                                                    width={'100%'}
                                                    chartType="LineChart"
                                                    loader={<div>Cargando Datos</div>}
                                                    data={newClientsParsedByMonth}
                                                    options={LineOptionsUsers}
                                                    legend_toggle
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2">
                        <h3>Franquicias</h3>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <ReactTable
                                    data={state.data.franchises}
                                    class="-striped -highlight"
                                    multiSelectOption={false}
                                    defaultPageSize={state.data.franchises.length}
                                    showPageSizeOptions={false}
                                    showPagination={false}
                                    options={false}
                                    columns={columnsFranchisesTable}
                                    manual
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2">
                        <h3>Usuarios</h3>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <ReactTable
                                    data={state.data.users}
                                    class="-striped -highlight"
                                    multiSelectOption={false}
                                    defaultPageSize={state.data.users.length}
                                    showPageSizeOptions={false}
                                    showPagination={false}
                                    options={false}
                                    columns={columnsUsersTable}
                                    manual
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalObservation
                data={modalChange.data}
                visible={modalChange.visible}
                deliveries={state.data.deliveryUserHistory}
                onSave={submitDeliveryChange}
                handleChange={handleDeliveryChanges}
                handleClose={onCloseModalChange}
            />
        </Fragment>
    );
}

const ModalObservation = (props) => {
    const { data, visible, onSave, handleClose, handleChange, deliveries } = props;

    const handleSave = (e) => {
        e.preventDefault();
        const userId = data.user.value;
        const valueChange = data.value;

        onSave(userId, valueChange);
    };

    return (
        <Modal open={visible} onClose={handleClose} classNames={{ modal: 'customModal' }}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Conteo de cambios
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <Select
                        name="userDeliveryId"
                        placeholder="Selecciona el repartidor"
                        onChange={(value) => handleChange('user', value)}
                        value={data.user}
                        options={deliveries.map((user) => {
                            return {
                                value: user.userId,
                                label: user.userName,
                            };
                        })}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="number"
                        keyboardtype="numeric"
                        id="intentionalChange"
                        name="action"
                        onChange={(e) => handleChange('value', e.target.value)}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSave}>
                    aceptar
                </button>
            </div>
        </Modal>
    );
};

export default Dashboard;
